/* .app-container {
    max-width: 800px;
    margin: 0 auto;
  } */

  .banner {
    /* background-color: #121212d5; */
    text-align: center;
    color: #000000;
    padding: 2rem;
    border-radius: 1rem;
    margin: 6rem 5rem 3rem 5rem;
  }
  
  .banner-content {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    align-items: center;
  }
  
  .banner-text {
    flex-grow: 1;
    text-align: left;
  }
  
  .banner-image {
    flex-shrink: 0;
    margin-left: 2rem;
    width: 40%;
  }
  
  .banner-image img {
    width: 100%;
    height: auto;
  }
  
  .banner-text button {
    background-color: #BF1162;
    color: #fff;
    border: none;
    font-weight: 700;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5rem;
    margin-top: 1rem;
  }
  
  .banner-text button:hover {
    background-color: #8a0342;
  }
  

  h1{
    font-size: 2rem;
    font-weight: 700;
  }

  span {
    color: #007bff;
  }
  
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  nav ul li {
    margin-right: 10px;
  }
  
  nav ul li:last-child {
    margin-right: 0;
  }
  
  button {
    background-color: #BF1162;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 1rem;
    cursor: pointer;
    font-weight: 700;
    border-radius: 5rem;
  }
  
  button:hover {
    background-color: #8a0342;
  }
  
  /* main div {
    margin-top: 30px;
  } */
  
  .section-content {
    display: flex;
    align-items: center;
    box-shadow: 0 0 6px rgb(203, 199, 199);
    border-radius: 3rem;
    padding: 2rem 3rem 2rem 0;
    margin: 6rem 15rem;
  }

  .section-content-izq {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    /* box-shadow: 0 0 6px rgb(203, 199, 199);
    border-radius: 3rem; */
    /* background-color: #f6f6f6; */
    padding: 2rem 3rem 2rem 3rem;
    margin: 6rem 4rem 0 7rem;
  }

  .section-content-last {
    display: flex;
    align-items: center;
    box-shadow: 0 0 6px rgb(203, 199, 199);
    border-radius: 3rem;
    padding: 2rem 3rem 2rem 0;
    margin: 6rem 6rem;
  }

  .section-content-last .section-image {
    width: 40%;
    /* flex: 1; */
    margin-right: 20px; /* Espaciado entre la imagen y el texto */
  }
  
  .section-content-last .section-image img {
    width: 70%; /* Asegura que la imagen ocupe todo el espacio disponible */
  }

  .section-content-izq .section-image {
    width: 35%;
    /* flex: 1; */
    text-align: center;
  }
  
  .section-content-izq .section-image img {
    width: 70%; /* Asegura que la imagen ocupe todo el espacio disponible */
  }

  .section-content-izq li {
    text-align: left;
  }

  
  .section-image {
    width: 50%;
    text-align: center;
    /* flex: 1; */
  }
  
  .section-image img {
    width: 70%; /* Asegura que la imagen ocupe todo el espacio disponible */
  }
  
  .section-text {
    flex: 1; /* Ocupa 1 parte de 2 */
  }
  
  .section-text h2,
  .section-text p {
    text-align: left; /* Texto justificado */
  }

  #preguntas {
    margin: 4rem 0;
    text-align: center;
  }
  


  /* Define las animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.banner-enter {
  animation: fadeIn 0.9s ease forwards;
}

.banner-exit {
  animation: fadeOut 0.9s ease forwards;
}

/* Define las animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fade-enter {
  animation: fadeIn .9s linear;
}

.fade-exit {
  animation: fadeOut 0.5s ease-in;
}

  
@media screen and (max-width: 768px) {
  .banner {
    margin:1rem;
  }
  .banner-content {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .banner-image {
    width: 100%;
    margin-left: 0;
    margin-top: 1.5rem; /* Ajuste de margen superior */
  }

  .banner-text {
    text-align: center;
  }

  h1 {
    font-size: 1.5rem;
  }

  .section-content {
    margin: 6rem 2rem; /* Ajuste de margen para pantallas pequeñas */
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
  }

  .section-content-last {
    margin: 6rem 2rem; /* Ajuste de margen para pantallas pequeñas */
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
  }

  .section-content p {
    font-size: .9rem;
  }
  .section-content-izq p {
    font-size: .9rem;
  }
  .section-content-last p {
    font-size: .9rem;
  }

  h2 {
    font-size: 1rem;
  }

  main {
    text-align: center;
  }

  li {
    text-align: left !important;
  }

  .section-content-izq,
  .section-content-last {
    margin: 6rem 1rem; /* Ajuste de margen para pantallas pequeñas */
    padding: 2rem;
  }

  .section-content-izq {
    flex-direction: column; /* Cambio de dirección para dispositivos móviles */
    text-align: center; /* Alineación de texto al centro */
    gap: 2rem;
  }

  .section-image {
    width: 100%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  .section-content-izq .section-image {
    width: 100%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }

  .section-image img {
    width: 50%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  .section-content-izq .section-image img {
    width: 45%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  .section-content-last .section-image {
    width: 100%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }

  .section-content-last .section-image img {
    width: 50%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  
}

@media (min-width: 576px) and (max-width: 991px) {
  .banner {
    margin:1rem;
  }
  .banner-content {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .banner-image {
    width: 40%;
    margin-left: 0;
    margin-top: 1.5rem; /* Ajuste de margen superior */
  }

  .banner-text {
    text-align: center;
  }

  h1 {
    font-size: 1.5rem;
  }

  .section-content {
    margin: 6rem 2rem; /* Ajuste de margen para pantallas pequeñas */
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
  }

  .section-content-last {
    margin: 6rem 2rem; /* Ajuste de margen para pantallas pequeñas */
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
  }

  .section-content p {
    font-size: .9rem;
  }
  .section-content-izq p {
    font-size: .9rem;
  }
  .section-content-last p {
    font-size: .9rem;
  }

  h2 {
    font-size: 1rem;
  }

  main {
    text-align: center;
  }

  li {
    text-align: left !important;
  }

  .section-content-izq,
  .section-content-last {
    margin: 6rem 1rem; /* Ajuste de margen para pantallas pequeñas */
    padding: 2rem;
  }

  .section-content-izq {
    flex-direction: column; /* Cambio de dirección para dispositivos móviles */
    text-align: center; /* Alineación de texto al centro */
    gap: 2rem;
  }

  .section-image {
    width: 100%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  .section-content-izq .section-image {
    width: 100%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }

  .section-image img {
    width: 20%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  .section-content-izq .section-image img {
    width: 18%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
  .section-content-last .section-image {
    width: 100%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }

  .section-content-last .section-image img {
    width: 20%; /* Ancho completo para dispositivos móviles */
    margin-right: 0; /* Eliminar margen derecho */
  }
}
